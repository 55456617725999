<template>
  <div id="nav">
      <b-navbar toggleable="lg" type="light">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item><router-link to="/">Accueil</router-link></b-nav-item>
            <b-nav-item><router-link to="/formulaire">Confirmer ma présence</router-link></b-nav-item>
            <b-nav-item><router-link to="/programme">Programme</router-link></b-nav-item>
            <b-nav-item><router-link to="/hebergement">Hébergements</router-link></b-nav-item>
            <b-nav-item><router-link to="/acces">Accès</router-link></b-nav-item>
            <b-nav-item><router-link to="/contact">Contacter les futurs mariés</router-link></b-nav-item>
            <b-nav-item><a @click="logout()">Se déconnecter</a></b-nav-item>
            <b-nav-item class="mr-auto"><i>{{ day() }}</i></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  methods: {
    logout(){
      localStorage.removeItem('access-token');
      this.$router.push("/login");
    },
    day() {
      moment.updateLocale('fr', {
        relativeTime : {
          future: "J-%s",
          past:   "Il y a %s",
          s  : 'a few secondes',
          ss : '%d secondes',
          m:  "a minute",
          mm: "%d minutes",
          h:  "an heure",
          hh: "%d heures",
          d:  "a jour",
          dd: "%d jours",
          w:  "a semaine",
          ww: "%d semaines",
          M:  "a mois",
          MM: "%d mois",
          y:  "a année",
          yy: "%d années"
        }
      });
      return moment("20220604", "YYYYMMDD").endOf('hour').fromNow();
    } 
  }
}
</script>