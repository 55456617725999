import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import verifyToken from './verifyToken'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      verifyToken(next)
    }
  },
  {
    path: '/formulaire',
    name: 'Formulaire',
    component: () => import('../views/Formulaire.vue'),
    beforeEnter: (to, from, next) => {
      verifyToken(next)
    }
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import('../views/Confirmation.vue'),
    beforeEnter: (to, from, next) => {
      verifyToken(next)
    }
  },
  {
    path: '/contact',
    name: 'Contacter les futurs mariés',
    component: () => import('../views/Contact.vue'),
    beforeEnter: (to, from, next) => {
      verifyToken(next)
    }
  },
  {
    path: '/acces',
    name: 'Accès',
    component: () => import('../views/Acces.vue'),
    beforeEnter: (to, from, next) => {
      verifyToken(next)
    }
  },
  {
    path: '/programme',
    name: 'Programme',
    component: () => import('../views/Programme.vue'),
    beforeEnter: (to, from, next) => {
      verifyToken(next)
    }
  },
  {
    path: '/hebergement',
    name: 'Hébergement',
    component: () => import('../views/Hebergement.vue'),
    beforeEnter: (to, from, next) => {
      verifyToken(next)
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
