import jwt from "jsonwebtoken";

function verifyToken(next) {
  const saveToken = localStorage.getItem("access-token");
  if (!saveToken) next({ path: "/login" });

  try {
    const verified = jwt.decode(saveToken, process.env.VUE_APP_ACCESS_TOKEN_SECRET);
    if (verified) next();
  } catch (error) {
    console.log(error);
    next({ path: "/login" });
  }
}

export default verifyToken;
