<template>
  <div id="home">
    <Menu/>
    <b-container id="images" fluid>
      <VueSlickCarousel class="hiddenLargeScreen" v-bind="settings">
        <div><img src="../assets/images/img1.jpeg" class="p-2" width="100%"></div>
        <div><img src="../assets/images/img3.jpeg" class="p-2" width="100%"></div>
        <div><img src="../assets/images/img2.jpeg" class="p-2" width="100%"></div>
      </VueSlickCarousel>
      <b-row class="hiddenSmallScreen">
        <b-col lg="4"><img src="../assets/images/img1.jpeg" width="100%"></b-col>
        <b-col lg="4"><img src="../assets/images/img3.jpeg" width="100%"></b-col>
        <b-col lg="4"><img src="../assets/images/img2.jpeg" width="100%"></b-col>
      </b-row>
    </b-container>
    <b-container
      class="d-flex justify-content-center pt-5 texte"
    >
      <b-row>
        <b-col lg="2" md="2" sm="2" class="p-1 d-flex align-items-center justify-content-center hiddenSmallScreen">
          <div
            class="d-flex flex-column"
          >
            <h4>04</h4>
            <span class="line"></span>
            <h4>06</h4>
            <span class="line"></span>
            <h4>22</h4>
          </div>
        </b-col>
        <b-col xs="12" class="p-1 pl-5 d-flex align-items-center justify-content-start hiddenLargeScreen">
          <div
            class="d-flex"
          >
            <h4>04</h4>
            <span class="lineVertical"></span>
            <h4>06</h4>
            <span class="lineVertical"></span>
            <h4>22</h4>
          </div>
        </b-col>
        <b-col lg="10" md="10" sm="10" xs="12" class="p-1 pl-5 d-flex align-items-center text">
          <h4 class="text-left">Laëtitia <span class="sheLovelyFont">et</span> Pierre vont se dire <span class="sheLovelyFont">oui</span> <br> et vous invitent à voyager aux <span class="sheLovelyFont">portes des Cevennes</span></h4>
        </b-col>
      </b-row>
    </b-container>
    <b-modal class="modal" v-model="modalShow" ref="my-modal" hide-footer hide-header centered>
      <div class="d-block text-center">
        <h3>As-tu confirmé ta présence à notre mariage ?</h3>
      </div>
      <b-button class="mt-3 success" block @click="goConfirm">Non, je m'en occupe tout de suite !</b-button>
      <b-button class="mt-3 danger" variant="outline-danger" block @click="hideModal">Oui, je l'ai déjà fais ! :)</b-button>
    </b-modal>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import Menu from './../components/Menu.vue'

export default {
  components: {
    VueSlickCarousel,
    Menu
  },
  data() {
    return {
      modalShow: true,
      settings: {
        "dots": false,
        "centerMode": true,
        "centerPadding": '60px',
        "autoplay": true,
        "infinite": true,
        "speed": 200,
        "autoplaySpeed": 2000,
        "slidesToShow": 2,
        "slidesToScroll": 2,
        "initialSlide": 0,
        "arrows": false,
        "responsive": [
          {
            "breakpoint": 980,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
    }
  },
  methods: {
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    goConfirm() {
      this.$router.push('/formulaire');
    }
  }
}
</script>
<style>
#images .hiddenLargeScreen, .texte .hiddenLargeScreen {
  display: none !important;
}
@media (max-width: 980px) {
  #images .hiddenSmallScreen {
    display: none !important;
  }
  #images .hiddenLargeScreen {
    display: block !important;
  }
}
@media (max-width: 575px) {
  .texte .hiddenSmallScreen {
    display: none !important;
  }
  .texte .hiddenLargeScreen {
    display: block !important;
  }
}

</style>
