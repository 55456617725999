<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      token: false
    }
  },
  created() {
    if(localStorage.getItem("access-token") !== null) {
      this.token = true;
    }
  }
}
</script>

<style lang="scss">
@import '../src/assets/css/style.scss';
</style>
